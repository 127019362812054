<div class="modal-header">
    <h5 class="modal-title">{{title}}</h5>
    <button type="button" class="btn-close close pull-right" aria-label="Close" (click)="modalRef.hide()">
      <span aria-hidden="true" class="visually-hidden">&times;</span>
    </button>
  </div>
  <div class="modal-body text-center set">
    <p class="text-wrap shadow p-3 mb-5 bg-light rounded"></p>
    <ng-template #elseBlock>
      <p>{{confirmText}}</p>
    </ng-template>
  
    <div class="view-data-portion-add">
      <div class="row">
        <div class="col-lg-6 col-6">
          <p>Sr No :</p>
        </div>
        <div class="col-lg-6 col-6">
          <p>{{data.id}}</p>
        </div>
      </div>
      <div class="row">
        <div class="col-lg-6 col-6">
          <p>Quotation No :</p>
        </div>
        <div class="col-lg-6 col-6">
          <p>{{data.quotation_no}}</p>
        </div>
      </div>
      <div class="row">
        <div class="col-lg-6 col-6">
          <p>Quotation Subscription Start Date :</p>
        </div>
        <div class="col-lg-6 col-6">
          <p>{{data.license_start_date | date: 'd-M-y'}}</p>
        </div>
      </div>
      <div class="row">
        <div class="col-lg-6 col-6">
          <p>Quotation Subscription End Date :</p>
        </div>
        <div class="col-lg-6 col-6">
          <p>{{data.license_end_date | date: 'd-M-y' }}</p>
        </div>
      </div>
  
      <div class="row">
        <div class="col-lg-6 col-6">
          <p>Name of Customer :</p>
        </div>
        <div class="col-lg-6 col-6">
          <p>{{data.customer.company_name}}</p>
        </div>
      </div>
  
      <div class="row">
        <div class="col-lg-6 col-6">
          <p>Subscription Type :</p>
        </div>
        <div class="col-lg-6 col-6">
          <p>{{data.subcription_type}}</p>
        </div>
      </div>
  
      <div class="row">
        <div class="col-lg-6 col-6">
          <p> Description :</p>
        </div>
        <div class="col-lg-6 col-6">
          <p>{{data.description?.description}}</p>
        </div>
      </div>
  
      <div class="row">
        <div class="col-lg-6 col-6">
          <p>Plan :</p>
        </div>
        <div class="col-lg-6 col-6">
          <p>{{data.subcription_plan.type}}</p>
        </div>
      </div>
  
  
      <div class="row">
        <div class="col-lg-6 col-6">
          <p>Rate :</p>
        </div>
        <div class="col-lg-6 col-6">
          <p>{{data.rate}}</p>
        </div>
      </div>
  
      <div class="row">
        <div class="col-lg-6 col-6">
          <p>User :</p>
        </div>
        <div class="col-lg-6 col-6">
          <p>{{data.user}}</p>
        </div>
      </div>
  
  
      <div class="row">
        <div class="col-lg-6 col-6">
          <p>Total Amount :</p>
        </div>
        <div class="col-lg-6 col-6">
          <p>{{data.total_amount}}</p>
        </div>
      </div>
  
      <div class="row">
        <div class="col-lg-6 col-6">
          <p>Discount :</p>
        </div>
        <div class="col-lg-6 col-6">
          <p>{{data.discount}}</p>
        </div>
      </div>
  
  
      <div class="row">
        <div class="col-lg-6 col-6">
          <p>Final Amount :</p>
        </div>
        <div class="col-lg-6 col-6">
          <p>{{data.final_amount}}</p>
        </div>
      </div>
  
      <div class="row">
        <div class="col-lg-6 col-6">
          <p>CGST :</p>
        </div>
        <div class="col-lg-6 col-6">
          <p>{{data.CGST}}</p>
        </div>
      </div>
  
  
      <div class="row">
        <div class="col-lg-6 col-6">
          <p>SGST :</p>
        </div>
        <div class="col-lg-6 col-6">
          <p>{{data.SGST}}</p>
        </div>
      </div>
  
      <div class="row">
        <div class="col-lg-6 col-6">
          <p>IGST :</p>
        </div>
        <div class="col-lg-6 col-6">
          <p>{{data.IGST}}</p>
        </div>
      </div>
  
  
      <div class="row">
        <div class="col-lg-6 col-6">
          <p>Rounding Off :</p>
        </div>
        <div class="col-lg-6 col-6">
          <p>{{data.rounding_off}}</p>
        </div>
      </div>
  
      <div class="row">
        <div class="col-lg-6 col-6">
          <p>Receivable Amount :</p>
        </div>
        <div class="col-lg-6 col-6">
          <p>{{data.receivable_amount}}</p>
        </div>
      </div>
  
      <div class="view-data-confirm text-center my-3">
      </div>
  
    </div>
  
  
  </div>
