import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { PaymentStatusComponent } from './payment-status/payment-status.component';

const routes: Routes = [
  {
    path : '', redirectTo : 'auth' , pathMatch : 'full'
  },
  {
  path : 'auth',
  loadChildren : () => import('./auth/auth.module').then(m=>m.AuthModule)
  },
  {
  path : '', loadChildren : () => import('./home/home.module').then(m=>m.HomeModule)
  },
  {
    path: 'payment-status', 
    component: PaymentStatusComponent
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
