import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { environment } from "../../environment/environment";
import { catchError, finalize, map } from "rxjs/operators";
import { Router } from "@angular/router";
import { LocalStorageService } from "./local-storage.service";
import { ToastrService } from "ngx-toastr";
import { NgxSpinnerService } from "ngx-spinner";
import { firstValueFrom, throwError } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class HttpRequestsService {
  private hostUrl = environment.apiEndPoint;
  private taskopadUrl = environment.taskopadEndpoint;
  constructor(
    public http: HttpClient,
    private router: Router,
    private spinner: NgxSpinnerService,
    public localstorage: LocalStorageService,
    public toast: ToastrService
  ) {}

  /**
   * getHeader(headerOptions, doNotSendAuthorizationParam) => set header option with authorization token base on param
   * @param headerOptions in headeroption
   * @param doNotSendAuthorizationParam in authorization sent or not
   */
  getHeader(headerOptions: any, doNotSendAuthorizationParam: boolean , fromtaskopad?:any) {
    const headerParams: any = {};

    headerParams["Accept-Language"] = "en";
    if (doNotSendAuthorizationParam !== true) {
      if(fromtaskopad){
        headerParams["Authorization"] = environment.taskopadAuthToken
      }
      else{
        headerParams["Authorization"] = `Bearer ${this.localstorage.getLocalStore(
          "TOKEN"
        )}`;
      }
    }
    if (headerOptions) {
      Object.assign(headerParams, headerOptions);
    }
    const headers = new HttpHeaders(headerParams);
    return { headers };
  }

  /**
   * post(url, body, doNotSendAuthorizationParam?, headerOptions?) => post method base on params
   * @param url in url
   * @param body in body param
   * @param doNotSendAuthorizationParam in authorization sent or not
   * @param headerOptions in header option
   */
  post(
    url: string,
    body: any,
    doNotSendAuthorizationParam: boolean = false,
    headerOptions: any = {}
  ): Promise<any> {
    this.spinner.show();
    const options = this.getHeader(headerOptions, doNotSendAuthorizationParam);

    return firstValueFrom(
      this.http.post(`${this.hostUrl}${url}`, body, options).pipe(
        map((res) => res),
        catchError((err) => {
          this.handleerr(err);
          return throwError(() => err);
        }),
        finalize(() => {
          this.spinner.hide();
        })
      )
    );
  }

  postForTaskopad(
    url: string,
    body: any,
    loader?: any | false,
    doNotSendAuthorizationParam: boolean = false,
    headerOptions: any = {}
  ): Promise<any> {
    if (loader) {
      this.spinner.show();
    }
    const options = this.getHeader(headerOptions, doNotSendAuthorizationParam,true);

    return firstValueFrom(
      this.http.post(`${this.taskopadUrl}${url}`, body, options).pipe(
        map((res) => res),
        catchError((err) => {
          this.handleerr(err);
          return throwError(() => err);
        }),
        finalize(() => {
          this.spinner.hide();
        })
      )
    );
  }

  patch(
    url: string,
    body: any,
    doNotSendAuthorizationParam: boolean = false,
    headerOptions: any = {}
  ): Promise<any> {
    this.spinner.show();
    const options = this.getHeader(headerOptions, doNotSendAuthorizationParam);

    return firstValueFrom(
      this.http.patch(`${this.hostUrl}${url}`, body, options).pipe(
        map((res) => res),
        catchError((err) => {
          this.handleerr(err);
          return throwError(() => err);
        }),
        finalize(() => {
          this.spinner.hide();
        })
      )
    );
  }

  /**
   * get(url, doNotSendAuthorizationParam?, headerOptions?) => get method base on params
   * @param url in url
   * @param doNotSendAuthorizationParam in authorization sent or not
   * @param headerOptions in header option
   */

  get(
    url: string,
    doNotSendAuthorizationParam: boolean = false,
    headerOptions: any = {}
  ): Promise<any> {
    this.spinner.show();
    const options = this.getHeader(headerOptions, doNotSendAuthorizationParam);

    return firstValueFrom(
      this.http.get(`${this.hostUrl}${url}`, options).pipe(
        map((res) => res),
        catchError((err) => {
          this.handleerr(err);
          return throwError(() => err);
        }),
        finalize(() => {
          this.spinner.hide();
        })
      )
    );
  }

  getFromTaskopad(
    url: string,
    doNotSendAuthorizationParam: boolean = false,
    headerOptions: any = {},
    spinner?: any | true
  ): Promise<any> {
    if (spinner) {
      this.spinner.show();
    }
    const options = this.getHeader(headerOptions, doNotSendAuthorizationParam,true);

    return firstValueFrom(
      this.http.get(`${this.taskopadUrl}${url}`, options).pipe(
        map((res) => res),
        catchError((err) => {
          this.handleerr(err);
          return throwError(() => err);
        }),
        finalize(() => {
          if (spinner) {
            this.spinner.hide();
          }
        })
      )
    );
  }

  /**
   * get(url, doNotSendAuthorizationParam?, headerOptions?) => get method base on params
   * @param url in url
   * @param doNotSendAuthorizationParam in authorization sent or not
   * @param headerOptions in header option
   */
  getNoURL(url: string): Promise<any> {
    return firstValueFrom(
      this.http.get(url).pipe(
        map((res) => res),
        catchError((err) => {
          this.handleError(err);
          return throwError(() => err); // Using throw to create and throw the error
        })
      )
    );
  }

  /**
   * put(url, body, doNotSendAuthorizationParam?, headerOptions?) => put method base on params
   * @param url in url
   * @param body in body param
   * @param doNotSendAuthorizationParam in authorization sent or not
   * @param headerOptions in header option
   */

  put(
    url: any,
    body: any,
    headerOptions: any = {},
    doNotSendAuthorizationParam: boolean = false
  ): Promise<any> {
    this.spinner.show();
    const options = this.getHeader(headerOptions, doNotSendAuthorizationParam);

    return firstValueFrom(
      this.http.put(`${this.hostUrl}${url}`, body, options).pipe(
        map((res) => res),
        catchError((err) => {
          this.handleerr(err);
          return throwError(() => err);
        }),
        finalize(() => {
          this.spinner.hide();
        })
      )
    );
  }

  /**
   * delete(url, doNotSendAuthorizationParam?, headerOptions?) => delete method base on params
   * @param url in url
   * @param doNotSendAuthorizationParam in authorization sent or not
   * @param headerOptions in header option
   */
  delete(
    url: any,
    headerOptions: any = {},
    doNotSendAuthorizationParam: boolean = false
  ): Promise<any> {
    this.spinner.show();
    const options = this.getHeader(headerOptions, doNotSendAuthorizationParam);

    return firstValueFrom(
      this.http.delete(`${this.hostUrl}${url}`, options).pipe(
        map((res) => res),
        catchError((err) => {
          this.handleerr(err);
          return throwError(() => err);
        }),
        finalize(() => {
          this.spinner.hide();
        })
      )
    );
  }
  handleerr(err: any) {
    this.spinner.hide();
    this.handleError(err);
  }

  /**
   * handleError(err) => handle error message
   * @param err in arr object
   */
  handleError(err: any) {
    const error =
      err.error.error || err.error.message || err.error.responseMessage
        ? err.error.error || err.error.message || err.error.responseMessage
        : "Internal server error!";
    if (err.status === 400) {
      this.toast.error(error);
    } else if (err.status === 401) {
      this.toast.error(err.error.error ? err.error.error : "Session Expired!");
      this.localstorage.clearStorage();
      this.router.navigateByUrl("/");
    } else if (err.status === 500) {
      this.toast.error("Internal server error!");
    }
    this.spinner.hide();
  }
}
