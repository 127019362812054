import { Component, EventEmitter, OnInit } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-confirm-box',
  templateUrl: './confirm-box.component.html',
  styleUrls: ['./confirm-box.component.css']
})
export class ConfirmBoxComponent implements OnInit{
    confirmText : any;
    acceptButtonText : any;
    cancleButtonText : any;
    acceptButtonIsDangerClass : any;
    cancelButtonIsDangerClass : any;
    title : any;
    confirmTextClass : any;
    config = {
      allowappNumbersOnly: true,
      length: 6,
      isPasswordInput: true,
      disableAutoFocus: false,
      placeholder: '',
      inputStyles: {
        width: '34px',
        height: '34px'
      }
    };
    public event: EventEmitter<any> = new EventEmitter();
    public response: Subject<boolean> | any
  
    constructor(private modalRef: BsModalRef) { }
  
    ngOnInit() {
      this.response = new Subject();
    }
  
    choice(bool:boolean): void {
      this.response.next(bool);
   
    this.modalRef.hide();
    }
  
    hideConfirmationModal() {
      this.modalRef.hide();
    }
  
    closeModal() {
        this.modalRef.hide();
    }
  
}
