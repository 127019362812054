import { Injectable } from "@angular/core";
import { HttpRequestsService } from "./http-requests.service";
import { BehaviorSubject, Subject } from "rxjs";
import { HttpParams } from "@angular/common/http";

@Injectable({
  providedIn: "root",
})
export class ApiService {
  constructor(private http: HttpRequestsService) {}
  userDetails: BehaviorSubject<any> = new BehaviorSubject(null);
  projectManagers: BehaviorSubject<any> = new BehaviorSubject([]);
  public pdfDownloadChange: Subject<boolean> = new Subject<boolean>();
  url: any = "https://invoice-api.taskopad.com/api";
  // url : any = 'http://192.168.3.154:9000/api'

  login = async (data: any) => {
    return this.http.post(`/auth/login`, data);
  };

  signUp = async (data: any) => {
    return this.http.post(`/auth/registration`, data);
  };

  forgot = async (data: any) => {
    return this.http.post(`/auth/forgotpassword`, data);
  };

  change = async (data: any) => {
    return this.http.post(`/auth/changepassword`, data);
  };
  updateCustomerDetails = async (data:any) => {
    return this.http.post(`/customer/updateCustomerDetails`,data)
  }

  customer = async (data: any) => {
    return this.http.post(`/customer/registration`, data);
  };

  getCustomerByMail = async (email: any) => {
    return this.http.get(`/customer/getCustomerDetails?email=${email}`);
  };

  getUser = async (page: any) => {
    return this.http.get(`/auth/getAll?page=${page}`);
  };

  updateUser = async (userId: any, data: any) => {
    return this.http.put(`/auth/update/${userId}`, data);
  };

  gstFile = async (customerId: any, data: any) => {
    return this.http.put(`/customer/gstFileUpload/${customerId}`, data);
  };

  deleteUser = async (id: number) => {
    return this.http.delete(`/auth/delete/${id}`);
  };

  checkmail = async (email: any) => {
    return this.http.getFromTaskopad(
      `/invoice/billing-details?email=${email}`,
      false
    );
  };

  //  Billing Detail edit from taskopad url
  editBillingDetails = async (data:any) => {
    return this.http.postForTaskopad(
      `/profile/billingDetailsFromAdmin`,
      {data:data}
    );
  }
  // <--------------------------------------------------------------------------------------------------------------------------------- -->
  // Add description , plan, billseries , payment gateway , industry-Type

  descriptionAdd = async (data: any) => {
    return this.http.post(`/description/add`, data);
  };

  planAdd = async (data: any) => {
    return this.http.post(`/subcriptionPlan/add`, data);
  };

  billSeriesAdd = async (data: any) => {
    return this.http.post(`/billSeries/add`, data);
  };

  paymentGatewayAdd = async (data: any) => {
    return this.http.post(`/paymentGateway/add`, data);
  };

  industryTypeAdd = async (data: any) => {
    return this.http.post(`/industryType/add`, data);
  };
  // <--------------------------------------------------------------------------------------------------------------------------------- -->

  customerList = async (page?: any | 1, pageLimit?: any | 10) => {
    return this.http.get(`/customer/getAll?page=${page}&limit=${pageLimit}`);
  };
  allCustomerList = async () => {
    return this.http.get(`/customer/getAll`);
  };


  getDetailsList = async () => {
    return {
      code: 200,
      result: [
        {
          UserID: 1,
          Name: "ridham",
          company: ["first", "secoond", "third", "fourth"],
          Email_ID: "ridhampansuriya810@gmail.com",
          Mobilenumber: 123456789,
          Country_Code: "+91",
          createdAt: "2024/11/11",
          ExpiredAt: "2025/12/10",
        },
        {
          UserID: 2,
          Name: "ridham",
          company: ["first", "secoond", "third", "fourth"],
          Email_ID: "ridhampansuriya810@gmail.com",
          Mobilenumber: 123456789,
          Country_Code: "+91",
          createdAt: "2024/11/11",
          ExpiredAt: "2025/12/10",
        },
        {
          UserID: 3,
          Name: "ridham",
          company: ["first", "secoond", "third", "fourth"],
          Email_ID: "ridhampansuriya810@gmail.com",
          Mobilenumber: 123456789,
          Country_Code: "+91",
          createdAt: "2024/11/11",
          ExpiredAt: "2025/12/10",
        },
        {
          UserID: 4,
          Name: "ridham",
          company: [
            "first",
            "secoond",
            "secoond",
            "secoond",
            "secoond",
            "third",
            "fourth",
          ],
          Email_ID: "ridhampansuriya810@gmail.com",
          Mobilenumber: 123456789,
          Country_Code: "+91",
          createdAt: "2024/11/11",
          ExpiredAt: "2025/12/10",
        },
        {
          UserID: 5,
          Name: "ridham",
          company: ["first", "secoond", "third", "fourth"],
          Email_ID: "ridhampansuriya810@gmail.com",
          Mobilenumber: 123456789,
          Country_Code: "+91",
          createdAt: "2024/11/11",
          ExpiredAt: "2025/12/10",
        },
      ],
      totalItems: 25,
    };
  };

  getInvoiceList = async (email: any, page: any, limit: any) => {
    const param = this.generateQueryParams({
      email: email,
      page: page,
      limit: limit,
    });
    return this.http.get(`/invoiceData/findCustomerInvoice?${param}`);
  };
  getPaymentList = async (page: any, limit: any, email: any) => {
    const params = this.generateQueryParams({
      page: page,
      limit: limit,
      email: email,
    });
    return this.http.get(`/paymentRecord/getPaymentRecord?${params}`);
  };

  getCompanyList = async (id: any) => {
    return this.http.getFromTaskopad(`/invoice/companies?userId=${id}`);
  };

  updateAndLinkPayment = async (payload: any) => {
    return this.http.put(`/paymentRecord/updateAndLinkPayment`, payload);
  };
  getUserDetails = async (id: any) => {
    return this.http.getFromTaskopad(
      `/invoice/user-data?userId=${id}&tab=owner-details`
    );
  };

  getUserOfCompany = async (id: any) => {
    return this.http.getFromTaskopad(`/invoice/company-details?id=${id}`);
  };

  getTotalUsers = async ( id?: any) => {
    return this.http.getFromTaskopad(
      `/invoice/user-data?userId=${id}&tab=users-details`
    );
  };
  get_TotalUsers = async ( paramobj: any) => {
    return this.http.postForTaskopad(
      `/invoice/getAllUsers`,paramobj
    );
  };


  mainCustomerList = async (payload?: any) => {
    return this.http.postForTaskopad(`/subscription/customerListing`, payload);
  };

  ownerDetail = async ( id:any) => {
    return this.http.getFromTaskopad(`/invoice/getOwnerDetails?userId=${id}`)
  };

  countryList = async () => {
    return this.http.get(`/country/getAll`);
  };

  stateListById = async (isoCode: any) => {
    return this.http.get(`/state/countryWisestate?countryCode=${isoCode}`);
  };

  cityListById = async (isoCode: any, stateId: any) => {
    return this.http.get(
      `/city/getAllCities?countryCode=${isoCode}&stateCode=${stateId}`
    );
  };

  updateCustomer = async (customerId: any, data: any) => {
    return this.http.put(`/customer/update/${customerId}`, data);
  };

  getLogDetails = async (params:any) => {
    const param = this.generateQueryParams(params)
    return this.http.get(`/customer/getLogDetails?${param}`);
  }

  getUserByName = async (params:any) => {
    const param = this.generateQueryParams(params);
    return this.http.get(`/auth/getAll?${param}`);
  }
  // <---------------------------------------------------------------------------------------------------------------------------------
  // Invoice Data table

  invoiceList = async (page: number, limit?: any, type?: any,search?:any,startDate?:any,endDate?:any,plan?:any) => {
    const params = this.generateQueryParams({
      page: page,
      limit: limit,
      invoiceType: type,
      search:search,
      startDate:startDate,
      endDate:endDate,
      plan:plan
    });
    return this.http.get(`/invoiceData/getAll?${params}`);
  };

  invoicebyId = async (id: number) => {
    return this.http.get(`/invoiceData/findById/${id}`);
  };

  subscriptionPlan = async (page: number = 1) => {
    return this.http.get(`subcriptionPlan/getAll?page=${page}`);
  };

  customerbyId = async (id: number) => {
    return this.http.get(`/customer/findById/${id}`);
  };

  invoiceSubmit = async (data: any) => {
    return this.http.post(`/invoiceData/create`, data);
  };

  updateInvoice = async (invoiceId: any, data: any) => {
    return this.http.put(`/invoiceData/update/${invoiceId}`, data);
  };

  deleteInvoice = async (id: number) => {
    return this.http.delete(`/invoiceData/delete/${id}`);
  };

  // <---------------------------------------------------------------------------------------------------------------------------------
  // Quotation Data table

  quotationList = async (page: number | 1, limit?: any | 10, email?: any) => {
    const param = this.generateQueryParams({
      email: email,
      page: page,
      limit: limit,
    });
    return this.http.get(`/quotation/getAll?${param}`);
  };

  quotationHistoryList = async (
    page: number | 1,
    limit?: any | 10,
    email?: any
  ) => {
    const param = this.generateQueryParams({
      email: email,
      page: page,
      limit: limit,
    });
    return this.http.get(`/quotation/getAllHistory?${param}`);
  };

  quotationHistorycustomerbyId = async (id: number) => {
    return this.http.get(`/quotation/history/findById/${id}`);
  };

  quotatonbyId = async (id: number) => {
    return this.http.get(`/quotation/findById/${id}`);
  };

  quotationsubscriptionPlan = async (page: number = 1) => {
    return this.http.get(`subcriptionPlan/getAll?page=${page}`);
  };

  quotationcustomerbyId = async (id: number) => {
    return this.http.get(`/customer/findById/${id}`);
  };

  quotationSubmit = async (data: any) => {
    return this.http.post(`/quotation/create`, data);
  };

  updateQuotation = async (quotationId: any, data: any) => {
    return this.http.put(`/quotation/update/${quotationId}`, data);
  };

  deleteQuotation = async (id: number) => {
    return this.http.delete(`/quotation/delete/${id}`);
  };

  // <--------------------------------------------------------------------------------------------------------------------------------- -->

  // <--------------------------------------------------------------------------------------------------------------------------------- -->

  // description

  description = async () => {
    return this.http.get(`/description/getAll`);
  };

  updateDescription = async (descriptionId: any, data: any) => {
    return this.http.put(`/description/update/${descriptionId}`, data);
  };

  deleteDescription = async (id: number) => {
    return this.http.delete(`/description/delete/${id}`);
  };
  // <--------------------------------------------------------------------------------------------------------------------------------- -->

  // <--------------------------------------------------------------------------------------------------------------------------------- -->

  // plan
  plan = async () => {
    return this.http.get(`/subcriptionPlan/getAll`);
  };

  updatePlan = async (planId: any, data: any) => {
    return this.http.put(`/subcriptionPlan/update/${planId}`, data);
  };

  deletePlan = async (id: number) => {
    return this.http.delete(`/subcriptionPlan/delete/${id}`);
  };

  // <--------------------------------------------------------------------------------------------------------------------------------- -->

  // bill series
  billSeries = async (tab:any) => {
    return this.http.get(`/billSeries/getAll?seriesType=${tab}`);
  };

  updatebillSeries = async (seriesId: any, data: any) => {
    return this.http.put(`/billSeries/update/${seriesId}`, data);
  };

  deletebillSeries = async (id: number) => {
    return this.http.delete(`/billSeries/delete/${id}`);
  };

  // <--------------------------------------------------------------------------------------------------------------------------------- -->
  // payment gateway
  paymentGateway = async () => {
    return this.http.get(`/paymentGateway/getAll`);
  };

  updatepaymentGateway = async (gatewayId: any, data: any) => {
    return this.http.put(`/paymentGateway/update/${gatewayId}`, data);
  };

  deletepaymentGateway = async (id: number) => {
    return this.http.delete(`/paymentGateway/delete/${id}`);
  };

  // <--------------------------------------------------------------------------------------------------------------------------------- -->
  // Industry Type

  industryType = async () => {
    return this.http.get(`/industryType/getAll`);
  };

  updateindustryType = async (industryTypeId: any, data: any) => {
    return this.http.put(`/industryType/update/${industryTypeId}`, data);
  };

  deleteindustryType = async (id: number) => {
    return this.http.delete(`/industryType/delete/${id}`);
  };
  // <--------------------------------------------------------------------------------------------------------------------------------- -->
  // Industry Type

  //   createPaymentGateway =async (data: any) => {
  //     return this.http.post(`${this.url}payment-getway/createPaymentGetway`,data)
  // }

  // Dashboard Summary

  DashboardSummaryDetailed = async (queryParams?: any) => {
    return this.http.post(`/summary/details` + `${queryParams || ""}`, {});
  };

  LostClientSummaryDetailed = async (queryParams?: any) => {
    return this.http.post(`/summary/lost_client` + `${queryParams || ""}`, {});
  };

  downloadInvoice = async (id: number) => {
    return this.http.get(`/invoiceData/PDF/${id}`);
  };

  downloadQuotation = async (id: number) => {
    return this.http.get(`/quotation/PDF/${id}`);
  };

  DashboardSummary = async (queryParams?: any) => {
    return this.http.post(`/summary/summary` + `${queryParams || ""}`, {});
  };

  LostClientSummary = async (queryParams?: any) => {
    return this.http.post(
      `/summary/summary_lost_client` + `${queryParams || ""}`,
      {}
    );
  };

  // <--------------------------------------------------------------------------------------------------------------------------------- -->

  // Payment record

  paymentList = async (
    page: number,
    limit?: any,
    status?: any,
    invoiceType?: any,
    search?:any,
    psStart?:any,
    psEnd?:any,
    ssStart?:any,
    ssEnd?:any,
    seStart?:any,
    seEnd?:any
  ) => {
    const param = this.generateQueryParams({
      page: page,
      limit: limit,
      status: status,
      invoiceType: invoiceType,
      search: search,
      psStart:psStart,
      psEnd:psEnd,
      ssStart:ssStart,
      ssEnd:ssEnd,
      seStart:seStart,
      seEnd:seEnd
    });
    return this.http.get(`/paymentRecord/getAll?${param}`);
  };

  // quotatonbyId= async (id: number) => {
  //   return this.http.get(`/quotation/findById/${id}`)
  // }

  paymentbyId = async (id: number) => {
    return this.http.get(`/paymentRecord/findById/${id}`);
  };

  // quotationSubmit =  async (data:any) => {
  //   return this.http.post(`/quotation/create`,data)
  // }

  paymentSubmit = async (data: any) => {
    return this.http.post(`/paymentRecord/add`, data);
  };

  updatePayment = async (paymentId: any, data: any) => {
    return this.http.put(`/paymentRecord/update/${paymentId}`, data);
  };

  deletePayment = async (id: number) => {
    return this.http.delete(`/paymentRecord/delete/${id}`);
  };

  recievablePayment = async (companyName: any) => {
    return this.http.get(
      `/paymentRecord/getAllCustomerAmount?company_name=${companyName}`
    );
  };

  paymentGrid = async (billNo: any, companyName: any, page: number) => {
    return this.http.get(
      `/paymentRecord/getAllinvoiceCustomer?page=${page}&bill_no=${billNo}&customer_name=${companyName}`
    );
  };

  // <--------------------------------------------------------------------------------------------------------------------------------- -->

  sendInvoiceFile = async (invoice_no: any) => {
    return this.http.post(
      `/payment/invoice/paymentLink?invoice_no=${invoice_no}`,
      {}
    );
  };

  sendQuotationFile = async (quotation_no: any) => {
    return this.http.post(
      `/payment/quotation/paymentLink?quotation_no=${quotation_no}`,
      {}
    );
  };

  invoicedropdown = async (
    invoiceType: any,
    linkedInvoice?: any | undefined
  ) => {
    const param = this.generateQueryParams({
      invoiceType: invoiceType,
      linkedInvoice: linkedInvoice,
    });
    return this.http.get(`/invoiceData/getAll?${param}`);
  };

  quotationdropdown = async () => {
    return this.http.get(`/quotation/getAll`);
  };

  sendInvoiceMail = async (id: number) => {
    return this.http.get(`/invoiceData/send_pdf/${id}`);
  };

  sendQuotationMail = async (id: number) => {
    return this.http.get(`/quotation/send_pdf/${id}`);
  };

  paymentStatus = async (queryParams: any) => {
    return this.http.put(`/payment/fetch?${queryParams}`, {});
  };

  generateQueryParams(paramsObj: any) {
    paramsObj = this.cleanObject(paramsObj);
    let params = new HttpParams();
    Object.keys(paramsObj).forEach((key) => {
      if (paramsObj[key] != "") {
        params = params.set(key, paramsObj[key]);
      }
    });
    return params;
  }

  cleanObject(obj: any) {
    for (var propName in obj) {
      if (obj[propName] === "" || obj[propName] === undefined) {
        delete obj[propName];
      }
    }
    return obj;
  }


  getUserSubscriptionDetails = async (user_id: any) => {
    return this.http.getNoURL(
      `https://ms-sub.taskopad.com/subscription/currentActivePlan?user_id=${user_id}`
    );
  };
}
