import { Component, EventEmitter, OnInit } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-view-details',
  templateUrl: './view-details.component.html',
  styleUrls: ['./view-details.component.css']
})
export class ViewDetailsComponent implements OnInit {
  confirmText : any;
  title : any;
  confirmTextClass : any;
  data  : any;
  config = {
    allowappNumbersOnly: true,
    length: 6,
    isPasswordInput: true,
    disableAutoFocus: false,
    placeholder: '',
    inputStyles: {
      width: '34px',
      height: '34px'
    }
  };
  public event: EventEmitter<any> = new EventEmitter();
  public response: Subject<boolean> | any

  constructor(public modalRef: BsModalRef) { }


  ngOnInit() {
    this.response = new Subject();
  }

  choice(bool:boolean): void {
    this.response.next(bool);
 
  this.modalRef.hide();
  }

  hideConfirmationModal() {
    this.modalRef.hide();
  }


  closeModal() {
      this.modalRef.hide();
  }
}

