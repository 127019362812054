import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ApiService } from 'src/app/service/api.service';
import { Router } from '@angular/router';


@Component({
  selector: 'app-payment-status',
  templateUrl: './payment-status.component.html',
  styleUrls: ['./payment-status.component.css']
})
export class PaymentStatusComponent implements OnInit {

   paymentStatus = false
   apiCalled = false
  RazorpayPaymentLinkReferenceId: any;
  RazorpayPaymentLinkId: any;
  RazorpayPaymentLinkStatus: any;
  RazorpaySignature: any;
  RazorpayPaymentId: any;
  
  constructor(private activatedRoute: ActivatedRoute, public apiService : ApiService,private router:Router) {
    this.activatedRoute.queryParams.subscribe(paramsId => {
      
      this.RazorpayPaymentLinkId = paramsId['razorpay_payment_link_id']
      this.RazorpaySignature = paramsId['razorpay_signature']

    })
  }

  ngOnInit(): void {
    this.getPaymentStatus()
  }


  async getPaymentStatus(){ 
    const queryParams = `razorpay_payment_link_id=${this.RazorpayPaymentLinkId}&razorpay_signature=${this.RazorpaySignature}`;
    
    const respond:any = await this.apiService.paymentStatus(queryParams);

    if (respond.statusCode === 200) {
      this.apiCalled = true
      if (respond.data[0].status == "paid") {
        this.paymentStatus = true      
      }else{
        this.paymentStatus = false      
      }
    }
  }

  async Onclick() {
    this.router.navigate([`${'/auth/login'}`]);
  }

}  
