<!-- Successfully message box -->
<div class="message-table-click-opn mt-4" *ngIf="paymentStatus && apiCalled">
    <div class="top-part">
        <div class="correct-all-cls">
            <svg fill="#fff" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 26 26" width="40px" height="40px">
                <path
                    d="M 13 0.1875 C 5.925781 0.1875 0.1875 5.925781 0.1875 13 C 0.1875 20.074219 5.925781 25.8125 13 25.8125 C 20.074219 25.8125 25.8125 20.074219 25.8125 13 C 25.8125 5.925781 20.074219 0.1875 13 0.1875 Z M 19.734375 9.035156 L 12.863281 19.167969 C 12.660156 19.46875 12.335938 19.671875 12.015625 19.671875 C 11.695313 19.671875 11.34375 19.496094 11.117188 19.273438 L 7.085938 15.238281 C 6.8125 14.964844 6.8125 14.515625 7.085938 14.242188 L 8.082031 13.246094 C 8.355469 12.972656 8.804688 12.972656 9.074219 13.246094 L 11.699219 15.867188 L 17.402344 7.453125 C 17.621094 7.132813 18.0625 7.050781 18.382813 7.265625 L 19.550781 8.058594 C 19.867188 8.273438 19.953125 8.714844 19.734375 9.035156 Z" />
            </svg>
            <div class="success-text">
                <label>Success</label>
            </div>
        </div>

    </div>
    <div class="message-table-click-opn-body">
        <label>Your Payment has<br> been successfully paid.</label>
        <div class="broken-btn mt-4">
            <button class="btn btn-primary continue-btn-cls" type="button" (click)="Onclick()">Continue</button>
        </div>
    </div>
</div>

<div class="message-table-click-opn mt-4 error-message-box-cls" *ngIf="!paymentStatus && apiCalled">
    <div class="top-part">
        <div class="correct-all-cls">
            <img src="../../assets/icons/paymentStatus.svg" alt="icon">
            <div class="success-text">
                <label>Failed</label>
            </div>
        </div>

    </div>
    <div class="message-table-click-opn-body">
        <label>Your Payment is Failed, <br> Please try again by using same link</label>
        <div class="broken-btn mt-4">
            <button class="btn btn-primary continue-btn-cls" type="button">Continue</button>
        </div>
    </div>
</div>
