import { CUSTOM_ELEMENTS_SCHEMA, NgModule, isDevMode } from "@angular/core";
import { BrowserModule } from "@angular/platform-browser";

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HttpClientModule } from '@angular/common/http';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { CommonModule, DatePipe } from '@angular/common';
import { ToastrModule } from 'ngx-toastr';
import { LocalStorageService } from "./service/local-storage.service";
import { AvatarModule } from "ngx-avatar";
import { PaginationModule } from 'ngx-bootstrap/pagination';
import { ConfirmBoxComponent } from './modals/confirm-box/confirm-box.component';
import { ViewDetailsComponent } from "./modals/view-details/view-details.component";
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { QuotationViewDetailsComponent } from "./modals/quotation-view-details/quotation-view-details.component";
import { NgSelectModule } from '@ng-select/ng-select';
import { ValidationMessageComponent } from './shared/validation-message/validation-message.component';
import { PaymentStatusComponent } from './payment-status/payment-status.component';
import { NgxSpinnerModule } from "ngx-spinner";
import { ServiceWorkerModule } from "@angular/service-worker";



@NgModule({
  declarations: [
    AppComponent,
    ConfirmBoxComponent,
    ViewDetailsComponent,
    QuotationViewDetailsComponent,
    ValidationMessageComponent,
    PaymentStatusComponent
  ],
  imports: [
    CommonModule,
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    ToastrModule.forRoot({positionClass: 'toast-bottom-left'}),
    AvatarModule,
    NgSelectModule,
    PaginationModule.forRoot(),
    FormsModule,
    ReactiveFormsModule,
    BrowserAnimationsModule,
    NgxSpinnerModule.forRoot({ type: 'square-jelly-box' }),
    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: !isDevMode(),
      registrationStrategy: 'registerWhenStable:30000'
    })

  ],
  exports: [
    DatePipe
  ],

  providers: [LocalStorageService, DatePipe],
  bootstrap: [AppComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class AppModule { }
