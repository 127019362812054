import {  ApplicationRef, Component } from '@angular/core';
import { SwUpdate } from '@angular/service-worker';
import { concat, first, interval } from 'rxjs';
 class TimeConfig {
  public static minute:60;
  public static hour:6;
  public static second:60;
  public static milisecond:1000;
} 



@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
})
export class AppComponent{
  title = 'invoice-creation-angular';

  constructor(appRef: ApplicationRef, updates: SwUpdate) {
  const appIsStable$ = appRef.isStable.pipe(first((isStable:any) => isStable === true));
  const everySixHours$ = interval(TimeConfig.milisecond*TimeConfig.second*TimeConfig.minute);
  const everySixHoursOnceAppIsStable$ = concat(appIsStable$, everySixHours$);
    
  everySixHoursOnceAppIsStable$.subscribe(async () => {
    try {
      const updateFound = await updates.checkForUpdate();
      console.log(updateFound ? 'A new version is available.' : 'Already on the latest version.');
      if(updateFound){
        window.location.reload();      
      }
    } catch (err) {
      console.error('Failed to check for updates:', err);
    }
  });
}

}
